import React, { useState } from "react";
import BannerImg from './images/banner_img.gif'
import bsc from './images/bsc.png'
import map from './images/map.png'
import future from './images/future.png'
import meta from './images/meta.png'
import playstore from './images/playstore.png'
import appstore from './images/appstore.png'

const App = () => {
  const [currentAccount, setCurrentAccount] = useState(null);
  const [chainId, setChainId] = useState(null);
  const [ethModal, setEthModal] = useState(null)
  let ethereum = window.ethereum;

  try {

    //if account is changed
    ethereum.on('accountsChanged', (accounts) => {
      //update the account display to the new account
      setCurrentAccount(accounts[0]);

    })

    //if network is changed, update the display to the new chain ID
    ethereum.on('chainChanged', (chainID) => {
      setChainId('Network ID: ' + parseInt(chainID));

    })
  }
  catch (err) {
    console.log(err.message)
  }





  const connectWallet = async () => {

    //check if browser is ethereum injected
    if (!ethereum) return toggleEthModal()

    try {

      //get chain ID
      const chainID=await ethereum.request({method:'eth_chainId'})

      //if chain id does not match bsc chain id, request to switch
      if (parseInt(chainID)!= 56) {

        try {
          await ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{
              chainId: "0x38"
            }]

          });

          //proceed to update wallet
          updateWallet();
        }
        catch (err) {
          console.log(err.message)
        }

      }
      else{
        //update wallet details on the page
        updateWallet();
      }

    } catch (error) {

      console.log(error.message)
    }
  };




  const updateWallet = async () => {


    try {
      //get current chain id
      const chainID=await ethereum.request({method:'eth_chainId'})
      
      //if chain id does not match the bsc chain id do not display
      if(parseInt(chainID)==56){
        //get connected account
        const accounts = await ethereum.request({ method: 'eth_requestAccounts', })
        
        //display the output to the page
        setCurrentAccount(accounts[0]);

        setChainId('Network ID: ' + parseInt(chainID));
      
      }

    } catch (error) {
      console.log(error.message)


    }
  };

  //show modal when the browser is not an eth browser
  const toggleEthModal = () => {

    setEthModal(!ethModal);
  }

  //function to shorten address
  const shortenAddress = (address) => `${address.slice(0, 6)}...${address.slice(address.length - 6)}`;
  return (
    <>
      <div className="main">

        <header className="header">

          <h2 className="logo">ImpactDefi</h2>


          <input className="side-menu" type="checkbox" id="side-menu" />
          <label className="hamb" htmlFor="side-menu"><span className="hamb-line"></span></label>


          <nav className="nav">
            <ul className="menu">
              <li><a href="#">FAQs</a></li>
              <li><a href="#">White Paper</a></li>
              <li><a href="#">Contact</a></li>
            </ul>
          </nav>
        </header>




        <div className="container">
          <div className="banner">

            <h1>
              <span className="text-primary">Secured</span> Impact Defi Solution with Metamask
            </h1>

            <p>Welcome to the Metamask Connection Task from Impact Defi with React and CSS</p>


            {!currentAccount ? (
              <button className="btn"
                onClick={connectWallet}
              >
                Connect Wallet
              </button>
            ) : (
              <button className="btn">{shortenAddress(currentAccount)}</button>
            )
            }

          </div>

        </div>


        <div className="container">


          <div className="w-lg-50">
            <div className="contentWrapper text-center" style={{ backgroundImage: `url(${map})`, backgroundSize: 'cover' }}>



              <div className="contentLogoWrapper">
                <img src={bsc} height="30px" />
              </div>

              <br />


              <p><b>NETWORK ID</b></p>
              {!currentAccount ? (
                <button className="btn btn-default">...</button>
              ) : (
                <button className="btn btn-default" style={{ border: '2px solid white' }}>{chainId}</button>
              )
              }

              <br />

              <div className="hide-lg" style={{ backgroundImage: `url(${BannerImg})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover', height: '400px', marginTop: '-80px', }}>

              </div>

            </div>
          </div>


          <div className="w-lg-50 hide-sm auto-height">
            <div className="contentWrapper bg-none">
              <img src={BannerImg} />
            </div>
          </div>

        </div>






        <div className="container m-40">

          <div className="w-lg-50 contentWrapper bg-none auto-height" >
            <img style={{ borderRadius: '30px' }} width="100%" src={future} />
          </div>

          <div className="w-lg-50 contentWrapper auto-height">


            <h2>
              <span className="text-primary">Impact </span>
              Defi the future of blockchain technology
            </h2>

            <p>Join <span className="text-primary">Impact Defi</span> today by connecting to the binance
              smart chain and start your journey in to the world of financial freedom</p>
            <br />
            <button className="btn btn-secondary">Learn More</button>

          </div>

        </div>




        <div className="container bg-secondary ">
          <div className="m-40 get-started ">



            <div className="text-center" >


              <h3>
                <span className="text-primary">Limited </span>
                Limited Slots Available for new users
              </h3>
              <p>Pick a form and get started on the journey to the top</p>
              <br />
              <button className="btn btn-secondary">GET STARTED</button>

            </div>
          </div>
        </div>



        <div className="container m-40">


          <div className="w-lg-50 contentWrapper bg-none  auto-height">


            <h2>
              Download <span className="text-primary">Metamask</span>  to Get Started

            </h2>

            <p> To enjoy the features of  <span className="text-primary">Impact Defi</span>, kindly download
              metamask from play store or app store or add the metamask extension to your desktop browser </p>
            <br />

            <img src={playstore} height="50px" />
            <img src={appstore} height="50px" />


          </div>


          <div className="w-lg-50 contentWrapper auto-height" >
            <img style={{ borderRadius: '30px' }} width="100%" src={meta} />
          </div>

        </div>




        {ethModal && (
          <div id="myModal" className="modal">

            <div className="modal-content text-center">
              <span className="close" onClick={toggleEthModal}>&times;</span>
              <div className="contentLogoWrapper">
                <img src={meta} height="30px" />
              </div>
              <p>PLEASE INSTALL METAMASK</p>
            </div>

          </div>
        )}



        <div className="container bg-secondary ">
          <div className="m-40 footer w-lg-50">
            <img src={meta} height="50px" />

            <br />
            <b><span className="text-primary">Footer </span> Section of Impact Defi</b>
            <p>We have successfully completed the footer section of the Impact Defi Solution Application and it is ready for deployment</p>

          </div>
        </div>
      </div>
    </>
  )
}

export default App;
